import { api } from '.';
import { POST_EVENT_RESOURCE } from '@/services/keys';

import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';

/**
 * @param {QueryParamsBuilder} params
 * @return {Promise.<data:PostEventTemplate[],meta:EndpointMeta>}
 */

export async function getPostEventTemplates(params = new QueryParamsBuilder(), clientId) {
  const partialUrl = api.createUrl({
    [POST_EVENT_RESOURCE]: undefined,
  });
  params.addFilter('clientId', clientId);
  params.addInclude('linkedClients');

  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
