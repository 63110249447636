<template functional>
  <div
    class="flex post-event-template border-gray-300 border rounded p-4 flex-wrap sm:flex-no-wrap"
    :class="data.staticClass || ''"
  >
    <div class="flex-grow-0 w-full sm:flex-grow mb-2 sm:mb-0"><div class="w-full sm:w-32 h-4 loading rounded" /></div>
    <div class="w-32 h-6 loading rounded-xl" />
    <div class="w-32 h-6 loading rounded mx-3" />
    <div class="w-8 h-6 loading rounded" />
  </div>
</template>

<script>
export default {
  name: 'PostEventTemplateLoader',
};
</script>

<style scoped>
.loading {
  @apply bg-gray-300 animate-pulse;
}
</style>
