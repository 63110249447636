import { api } from '.';
import { POST_EVENT_RESOURCE } from '@/services/keys';

export const getResources = postEventTemplateId => ({
  [POST_EVENT_RESOURCE]: postEventTemplateId,
});

/**
 * @param {String} postEventTemplateId
 * @returns {Promise.<string>}
 */

export async function deletePostEventTemplate(postEventTemplateId) {
  const partialUrl = api.createUrl(getResources(postEventTemplateId));
  const {
    data: {
      postEvent: { id: templateId },
    },
  } = await api.delete(partialUrl);
  return templateId;
}
