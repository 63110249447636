import { api } from '.';
import { POST_EVENT_RESOURCE } from '@/services/keys';

export const getResources = postEventId => ({
  [POST_EVENT_RESOURCE]: postEventId,
});

/**
 *
 * @param {PostEventTemplate} template
 * @return {Promise<string>}
 */
export async function updatePostEventTemplate(template) {
  const partialUrl = api.createUrl(getResources(template.id));
  const {
    data: {
      postEvent: { id: templateId },
    },
  } = await api.update(partialUrl, template.payload());
  return templateId;
}
