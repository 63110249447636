var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass:
        "flex post-event-template border-gray-300 border rounded p-4 flex-wrap sm:flex-no-wrap",
      class: _vm.data.staticClass || "",
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "w-32 h-6 loading rounded-xl" }),
      _c("div", { staticClass: "w-32 h-6 loading rounded mx-3" }),
      _c("div", { staticClass: "w-8 h-6 loading rounded" }),
    ]
  )
}
var staticRenderFns = [
  function (_c, _vm) {
    return _c(
      "div",
      { staticClass: "flex-grow-0 w-full sm:flex-grow mb-2 sm:mb-0" },
      [_c("div", { staticClass: "w-full sm:w-32 h-4 loading rounded" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }