import { api } from '.';
import { POST_EVENT_RESOURCE } from '@/services/keys';

import ResponseBuilder from '@/model/shared/ResponseBuilder';

export const getResources = () => ({
  [POST_EVENT_RESOURCE]: undefined,
});

/**
 *
 * @param {PostEventTemplate} template
 * @param {String} clientId
 * @return {Promise.<Template>}
 */

export async function createPostEventTemplate(template, clientId) {
  const partialUrl = api.createUrl(getResources());
  const { data } = await api.create(partialUrl, {
    ...template.payload(),
    clientId,
  });
  return new ResponseBuilder(data).build();
}
